import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Invalid Username or Password: a useless security measure",
  "date": "2014-12-07T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://kev.inburke.com/kevin/invalid-username-or-password-useless/"
      }}>{`Kevin Burke on his blog`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you tell an attacker the email address is wrong, they’ll try a different one. If you tell them the password is wrong, then an attacker knows that the username is correct, and can go on to try a bunch of passwords for that username until they hit the right one. So sites won’t tell you which one is wrong, to try and avoid the information disclosure.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Unfortunately `}<strong parentName="p">{`this assumes that there’s no other way for an attacker to discover whether a username/email address is registered for a service.`}</strong>{` This assumption is incorrect.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`99.9% of websites on the Internet will only let you create one account for each email address. So if you want to see if an email address has an account, `}<strong parentName="p">{`try signing up for a new account with the same email address`}</strong>{`.`}</p>
    </blockquote>
    <p>{`I’ve personally always hated this. Shouldn’t we try to communicate as clearly as possible to our users? Most people have multiple email addresses and it’s easy to forget which one you use for which service. If it doesn’t really make things more secure then I don’t see why anyone should continue doing this.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      